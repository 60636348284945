import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, { NavLinks, NavLink, PrimaryLink } from "components/headers/light.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ContentWithVerticalPadding, Container as ContainerBase } from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as QuotesLeftIconBase } from "images/quotes-l.svg"
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg"

const Container = tw(ContainerBase)`my-8 lg:my-10  bg-[#d3b8ac] text-[#401800] -mx-8 p-8 `;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)` flex flex-col items-center `;
;
const Heading = tw(SectionHeading)`sm:text-xl md:text-2xl lg:text-3xl `;
const Description = tw(SectionDescription)`text-[#764640] text-center lg:text-left mx-auto max-w-screen-md`;

const ImageColumn = tw(Column)` lg:ml-8 w-full lg:w-[40rem] flex items-center justify-center`;
const Image = tw.img`w-full  rounded-3xl shadow-[0_35px_60px_-15px_rgba(245,240,234,0.8)]
`;


export default ({
  heading = "Prazer, sou Monalise Longhi, mas podem me chamar de Mona!",
  description = `
  Formada em Ciência da Informação pela Universidade do Porto, mergulhei no incrível universo do mundo digital e todos os dias me apaixono mais por todas as possibilidades que ele oferece! 💻💡\n 
  Tudo começou em 2019, quando tive a oportunidade de fazer parte de uma agência de marketing. Foi amor à primeira "curtida"! Desde então, venho explorando, aprendendo e criando estratégias incríveis para transformar redes sociais em verdadeiros negócios de sucesso.\n 
  Atualmente, chamo Portugal de lar, mas o meu alcance vai muito além das fronteiras. Com o oceano Atlântico como testemunha, atendo tanto  em Portugal quanto no Brasil. Afinal, o digital não conhece limites, não é mesmo?\n  
  🎯 Meu objetivo é claro: fazer você brilhar no mundo digital! Quero ajudar você a crescer, alcançar seus objetivos e dar aquele "up" nas suas redes sociais. Juntos, vamos alavancar a sua presença online e transformar sonhos em resultados palpáveis.\n 
  `,
  imageSrc = "https://lh3.googleusercontent.com/pw/AIL4fc9z8-VT6s-wAr9V2ebM_5B8Kk8TlOClxs8dgy1alTHyBG_jKqRbF9wRLeA3jNHBxdiVD-5IExgOt9I-WDO04c-FBHFirGPFGj2OYQ_bxWb5tpoST5dR=w2400",
  primaryButtonUrl = "https://google.com",
  primaryButtonText = "Get Started",
  buttonRounded = true,
  features = ["Available in 7 Locations", "Premium Internet Backbone", "99.99% Uptime SLA"],
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;

  return (
    <Container>
      <Row>
        <TextColumn>
          <Heading>{heading}</Heading>
          {description && <Description dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br />') }} />}
        </TextColumn>
        <ImageColumn>

          <Image src={imageSrc} />
        </ImageColumn>
      </Row>
    </Container>

  );
};
