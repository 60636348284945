import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8 `;
const ContentWrapper = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24 flex flex-row`
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-gray-400 text-center mx-auto max-w-screen-md`;


export default ({
  subheading = "Oi Leo",
  heading = "Over 9000 Projects Completed",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
}) => {
  return (
    <Container>
      <ContentWrapper>

        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <div>
          <img src="https://lh3.googleusercontent.com/pw/AIL4fc9z8-VT6s-wAr9V2ebM_5B8Kk8TlOClxs8dgy1alTHyBG_jKqRbF9wRLeA3jNHBxdiVD-5IExgOt9I-WDO04c-FBHFirGPFGj2OYQ_bxWb5tpoST5dR=w2400" alt="" />
        </div>
      </ContentWrapper>
    </Container>
  );
};
