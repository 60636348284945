import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import HeaderBase from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithVerticalPadding } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg"

const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl text-[#401800]`;
const Heading = tw(SectionHeading)`text-left leading-snug xl:text-6xl`;
const Description = tw(SectionDescription)`mt-4 lg:text-base max-w-lg text-[#764640]`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 bg-[#d3b8ac] hocus:bg-[#401800] tracking-wide text-center py-5`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-[#ebdbd6] rounded xl:-mb-8`
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;


export default ({
  heading = "Gestão estratégica de redes sociais",
  description = "Transformando redes sociais em negócios de sucesso 🚀",
  imageSrc = "https://lh3.googleusercontent.com/pw/AIL4fc8ajaXIFm88duMSg01Sco6iUq9pyo8J3MutWkkNZvhDZfFVv0_Mw17BKQqeEE7nzdATuK8b4N5CkCdhzHmolcl_1En5EX3itW0nHnlhE5u4tX4MtKAt=w2400",
  imageDecoratorBlob = true,
  primaryButtonUrl = "https://google.com",
  primaryButtonText = "Fale Comigo",
  buttonRounded = true,
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  const navLinks = [

  ];
  return (
    <>
      <Header links={navLinks} />
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              <PrimaryButton as="a" href={"https://wa.me/+351930644665?text="} css={buttonRoundedCss}>
                {primaryButtonText}
              </PrimaryButton>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image src={imageSrc} />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
