import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container } from "components/misc/Layouts";
import WhatsappButton from "components/misc/WhatsappButton";
import InstagramButton from "components/misc/InstagramButton";

const PrimaryBackgroundContainer = tw.div`py-16 lg:py-20 bg-[#d3b8ac] rounded-lg relative text-[#401800]`
const Row = tw.div`px-4 sm:px-16 mx-auto flex justify-center items-center relative z-10 flex-col lg:flex-row text-center lg:text-left`;

const ColumnContainer = tw.div`lg:w-1/2 max-w-lg`
const TextContainer = tw(ColumnContainer)`text-2xl sm:text-4xl font-bold`;
const Subheading = tw.h6` opacity-75`;
const Heading = tw.h5``;

const LinksContainer = tw(ColumnContainer)`flex justify-center mt-6 lg:mt-0 flex-col gap-4 sm:flex-row`;


export default ({
  subheading = "Gostaria de saber mais ?",
  heading = "Entre em contato.",
  pushDownFooter = true
}) => {
  return (
    <Container id="contact" css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <ContentWithPaddingXl>
        <PrimaryBackgroundContainer>
          <Row>
            <TextContainer>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
            </TextContainer>
            <LinksContainer>
              <WhatsappButton phoneNumber="+351930644665" message="" />
              <InstagramButton username="monalonghi" />
            </LinksContainer>
          </Row>
        </PrimaryBackgroundContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
