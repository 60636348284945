import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Description from "components/hero/Description";
import Features from "components/features/ThreeColWithSideImage.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";


export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Description />
      <Features
        heading={<>Conheça os meus serviços</>}
        description="Vamos juntos alavancar a sua presença online e transformar sonhos em resultados palpáveis."
      />

      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
}
